import "../styles/Footer.scss";

import React, { useContext } from "react";

import { Link } from "gatsby";
import LocaleContext from "../context/LocaleContext.jsx";

function Footer(props) {
  const [locale] = useContext(LocaleContext);
  return (
    <div className="footer-container">
      <div className="footer-item">
        <Link to="/mentions-legales" className="footer-link">
          {locale === "fr"
            ? "Mentions légales et conditions de vente"
            : "legal notices and conditions of sale"}
        </Link>
      </div>
      <div className="footer-item">
        SNIPER © {new Date().getFullYear()}
        <a
          href="https://pecorino.studio"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pecorino Studio
        </a>
      </div>
      <div className="footer-item">
        {locale === "fr" ? "Tous droits réservés" : "All rights reserved"}
      </div>
    </div>
  );
}

export default Footer;
