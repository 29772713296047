import "../styles/Navbar.scss";
import "rodal/lib/rodal.css";

import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import LocaleContext from "../context/LocaleContext.jsx";
import NewsletterPopup from "./NewsletterPopup.jsx";
import ReactPlayer from "react-player";
import Rodal from "rodal";
import ScrollContainer from "react-indiana-drag-scroll";
import ShopClickedContext from "../context/ShopClickedContext.jsx";
import closeSvg from "../images/close.svg";
import fermerSvg from "../images/fermer.svg";
import flag_en from "../images/flag-en.png";
import flag_fr from "../images/flag-fr.png";
import icon_fb from "../images/icon_fb.svg";
import icon_insta from "../images/icon_insta.svg";
import { useLocation } from "@reach/router";
import { window } from "browser-monads";

function Navbar({ currentPage }) {
  const [locale, setLocale] = useContext(LocaleContext);
  const [menuOpened, setMenuOpened] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [curStory, setCurStory] = useState("");
  const [storyVisible, setStoryVisible] = useState(false);
  const [newsVisible, setNewsVisible] = useState(false);
  const [shopClicked, setShopClicked] = useContext(ShopClickedContext);
  const location = useLocation().pathname;

  useEffect(() => {
    setTimeout(() => {
      typeof document !== "undefined"
        ? localStorage["alreadyVisited"]
          ? setNewsVisible(false)
          : setNewsVisible(true)
        : setNewsVisible(false);
    }, 4000);
  }, []);

  useEffect(() => {
    if (shopClicked && location === "/") {
      window.scrollTo(0, (90 * window.innerHeight) / 100);
      setShopClicked(false);
    }
  }, [shopClicked, setShopClicked, location]);

  const breakpoint = 780;

  const data = useStaticQuery(graphql`
    query NavQuery {
      allContentfulStories {
        nodes {
          stories {
            videoYt
            titreStory
          }
        }
      }
    }
  `);

  const storiesTab = data.allContentfulStories.nodes[0].stories;

  //DÉTECTE ET ENREGISTRE LA LARGEUR DE LA FENÊTRE
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  const burgerButton = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <div className="navbarContainer">
      <Helmet
        title={currentPage + " | Sniper shop"}
        htmlAttributes={{ lang: "fr" }}
      >
        <body className={storyVisible || newsVisible ? "noscroll" : ""} />
      </Helmet>
      <NewsletterPopup
        visible={newsVisible}
        hideNewslettersPopup={() => {
          if (typeof document !== "undefined") {
            localStorage["alreadyVisited"] = true;
          }
          setNewsVisible(false);
        }}
        showCookies={
          typeof document !== "undefined" && !localStorage["alreadyVisited"]
        }
      />
      <motion.nav
        animate={{
          height: menuOpened && windowWidth < breakpoint ? "70vh" : "100px",
        }}
      >
        <div className="navcontainer">
          <div className="nav-left-container">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="title-subtitle-container"
            >
              <Link to="/" className="navTitle">
                {locale === "fr"
                  ? "SNIPER | BOUTIQUE OFFICIELLE"
                  : "SNIPER | OFFICIAL SHOP"}
              </Link>
              <div className="navSubTitle">
                <div className="rb-socials-container">
                  <a
                    href="https://www.facebook.com/officielsniper"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rb-socials-item"
                  >
                    <img id="facebook-logo" alt="facebook" src={icon_fb} />
                  </a>
                  <a
                    href="https://www.instagram.com/sniperofficiel/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rb-socials-item"
                  >
                    <img id="instagram-logo" alt="instagram" src={icon_insta} />
                  </a>
                </div>
              </div>
            </motion.div>
            <button
              onClick={() => setMenuOpened(!menuOpened)}
              className="burger-button-container"
            >
              <AnimatePresence>
                {!menuOpened && (
                  <motion.span
                    variants={burgerButton}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="gg-menu"
                    key="gg-menu"
                  />
                )}
                {menuOpened && (
                  <motion.span
                    variants={burgerButton}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="gg-close"
                    key="gg-close"
                  />
                )}
              </AnimatePresence>
            </button>
          </div>
          <div className="nav-right-container">
            {(menuOpened || windowWidth >= breakpoint) && (
              <ul className="nav-menu-list">
                <Link
                  to="/"
                  className={
                    currentPage === "Accueil"
                      ? "nav-menu-item active"
                      : "nav-menu-item"
                  }
                  onClick={() => {
                    setShopClicked(true);
                  }}
                >
                  {locale === "fr" ? "BOUTIQUE" : "SHOP"}
                </Link>
                <Link
                  to="/info"
                  className={
                    currentPage === "info"
                      ? "nav-menu-item active"
                      : "nav-menu-item"
                  }
                >
                  INFO
                </Link>
                <Link
                  to="/artists"
                  className={
                    currentPage === "artists"
                      ? "nav-menu-item active"
                      : "nav-menu-item"
                  }
                >
                  {locale === "fr" ? "ARTISTES" : "ARTISTS"}
                </Link>
                {/* <Link
                  to="/live"
                  className={
                    currentPage === "live"
                      ? "nav-menu-item active"
                      : "nav-menu-item"
                  }
                >
                  LIVE
                </Link> */}
              </ul>
            )}
            <div className="nav-right-bottom-container">
              <div className="rb-newsletter-container">
                <img
                  role="presentation"
                  className="flag"
                  src={locale ? (locale === "fr" ? flag_fr : flag_en) : flag_fr}
                  alt="langue"
                  onClick={() => setLocale(locale === "fr" ? "en" : "fr")}
                  onKeyDown={() => setLocale(locale === "fr" ? "en" : "fr")}
                />
              </div>
              <div className="rb-newsletter-container">
                <div className="newsletter-title">
                  <label htmlFor="newsletter">NEWSLETTER</label>
                  <button onClick={() => setNewsVisible(true)}>➞</button>
                </div>
                {/* <input
                id="newsletter"
                type="text"
                placeholder="email"
                className="newsletter-field-container"
              ></input> */}
              </div>
            </div>
          </div>
        </div>
      </motion.nav>
      {location === "/" && (
        <motion.div
          initial={{ top: 0 }}
          animate={{ top: 100 }}
          transition={{ delay: 2 }}
          className="storiesContainer"
        >
          <ScrollContainer
            vertical={false}
            hideScrollbars
            className="storiesInner"
          >
            {storiesTab.map((story, i) => {
              return (
                <motion.img
                  initial={{
                    borderColor: "#ffd90000",
                  }}
                  animate={{
                    borderColor: "#ffd900ff",
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 2,
                    delay: i,
                    repeatType: "reverse",
                  }}
                  src={`https://img.youtube.com/vi/${
                    story.videoYt.split("watch?v=")[1]
                  }/maxresdefault.jpg`}
                  alt="story"
                  key={i}
                  className="storyContainer"
                  onClick={() => {
                    setCurStory(story.videoYt);
                    setStoryVisible(true);
                  }}
                ></motion.img>
              );
            })}
          </ScrollContainer>
        </motion.div>
      )}
      <img
        role="presentation"
        src={locale === "fr" ? fermerSvg : closeSvg}
        alt="fermer"
        style={{ display: storyVisible ? "block" : "none" }}
        className="fermer"
        onClick={() => setStoryVisible(false)}
        onKeyDown={() => setStoryVisible(false)}
      />
      <Rodal
        visible={storyVisible}
        customStyles={{ width: "70%", height: "80%" }}
        onClose={() => setStoryVisible(false)}
        showCloseButton={false}
        className="rodalContainer"
        closeOnEsc
      >
        <ReactPlayer controls url={curStory} className="videoPlayer" />
      </Rodal>
    </div>
  );
}

export default Navbar;
