import "../styles/NewsletterPopup.scss";

import React, { useContext } from "react";

import Cookies from "universal-cookie";
import LocaleContext from "../context/LocaleContext.jsx";
import Rodal from "rodal";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";

function NewsletterPopup({ visible, hideNewslettersPopup, showCookies }) {
  const [locale] = useContext(LocaleContext);
  const cookies = new Cookies();
  const location = useLocation();

  // const customStyles = {
  //   height: "auto",
  //   bottom: "auto",
  //   top: "30%",
  // };

  return (
    <Rodal
      // customStyles={customStyles}
      visible={visible}
      showCloseButton={!showCookies}
      className="popupContainer"
      onClose={() => hideNewslettersPopup()}
      closeMaskOnClick={!showCookies}
    >
      {!showCookies && (
        <>
          <div className="title">NEWSLETTER</div>
          <form
            name="newsletter"
            // method="post"
            encType="application/x-www-form-urlencoded"
            // action="/"
            // netlify
            data-netlify={true}
          >
            <input type="hidden" name="newsletter" value="newsletter" />
            <div className="line">
              <input
                className="textInput"
                type="text"
                name="name"
                placeholder={
                  locale
                    ? locale === "fr"
                      ? "PRÉNOM NOM"
                      : "NAME"
                    : "PRÉNOM NOM"
                }
              />
            </div>
            <div className="line">
              <input
                className="textInput"
                type="email"
                name="email"
                placeholder="EMAIL"
              />
            </div>
            <div className="lineCheck">
              <input type="checkbox" name="checkbox" required></input>
              <div className="info">
                {locale === "fr"
                  ? "J’accepte de recevoir la newsletter Sniper dont je peux me désinscrire à tout moment."
                  : "I accept to subscribe to the Sniper newsletter and I understand I can unsubscribe from it at anytime."}
              </div>
            </div>
            <div className="line">
              <button type="submit">GO !</button>
            </div>
          </form>
        </>
      )}
      {showCookies && (
        <>
          <div className="title">
            COOKIES
            <br />
            <div className="info">
              {locale === "fr"
                ? "Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site"
                : "We use cookies to offer you the best experience on our website"}
            </div>
          </div>
          <div className="lineCookies">
            <button
              onClick={() => {
                if (typeof document !== "undefined") {
                  cookies.set("gatsby-gdpr-google-analytics", false, {
                    path: "/",
                  });
                  cookies.set("gatsby-gdpr-google-tagmanager", false, {
                    path: "/",
                  });
                  cookies.set("gatsby-gdpr-facebook-pixel", false, {
                    path: "/",
                  });
                  console.log("COOKIES NOK");
                }
                hideNewslettersPopup();
              }}
            >
              {locale === "fr" ? "ESSENTIELS" : "ESSENTIALS"}
            </button>
            <button
              className="buttonAll"
              onClick={() => {
                if (typeof document !== "undefined") {
                  cookies.set("gatsby-gdpr-google-analytics", true, {
                    path: "/",
                  });
                  cookies.set("gatsby-gdpr-google-tagmanager", true, {
                    path: "/",
                  });
                  cookies.set("gatsby-gdpr-facebook-pixel", true, {
                    path: "/",
                  });
                  initializeAndTrack(location);
                  console.log("COOKIES OK");
                }
                hideNewslettersPopup();
              }}
            >
              {locale === "fr" ? "TOUS" : "ALL"}
            </button>
          </div>
        </>
      )}
    </Rodal>
  );
}

export default NewsletterPopup;
